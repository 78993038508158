.topbar p {
  text-align: center;
  color: rgb(0, 0, 0);
  margin: 0;
}

.dot {
  height: 12px;
  width: 12px;
  background-color: rgb(254, 0, 0);
  border-radius: 50%;
  display: inline-block;
}