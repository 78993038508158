$primary: #023047;
$secondary: #bde0fe;
$secondaryDark: #96ccfc;
$error: #E76F51;
$lightError: #f0d2d1;
$success: #2A9D8F;
$lightSuccess: #cbf3f0;
$gray: #8b8b8b;

.grayText {
    color: $gray;
}

.btn-danger {
    background-color: $error;
    border: none;
    margin: 2px;
}

.btn-primary {
    background-color: $success;
    border: none;
    margin: 2px;
}
.btn-primary:hover {
    background-color: $success;
    border: none;
}
.spl15 {
    margin-left: 15px;
}
.pd32 {
    padding: 32px;
}

.table-responsive>.table-bordered {
    border: #e7e7e7;
}

.txtCenter{
    text-align: center;
}

.dashboardCardUser {
    background-color: #00b4dd;
    margin: 4px;
    border: 0px;
}

.dashboardCardAmbient {
    background-color: #ffa10a;margin: 4px;
        border: 0px;
}

.dashboardCardChart {
    background-color: #f50062;margin: 4px;
        border: 0px;
}
.dashboardCardExport {
    background-color: #72ce27;
    margin: 4px;
    border: 0px;
}

.userNeutralCard {
    background-color: #eff7f6;
    margin: 4px;
    border: 0px;
}

.noTextDec{
    text-decoration: none;
}

.noSpacing{
    padding: 0;
    margin: 0;
}

// env

.envBtnExpand{
    background-color: #eff7f6;
    color: $primary;
    border: none;
margin: 4px 4px 0px 0px;
    font-size: 1.1em;
}
.envNodeAdd {
    background-color: $lightSuccess !important;
    color: $primary;
    border-radius: 8px;
    margin: 4px 4px 0px 0px;
    vertical-align: middle;
    border: 0px;
    font-size: 1.1em;
}

.envNodeDelete {
    background-color: $lightError !important;
    color: $primary;
    border-radius: 8px;
    margin: 4px 4px 0px 0px;
    vertical-align: middle;
    border: 0px;
    font-size: 1.1em;
}

.envNodeDelete:hover {
    background-color: $lightError !important;
    color: $primary;
    border-radius: 8px;
    margin: 4px 4px 0px 0px;
    vertical-align: middle;
    border: 0px;
    font-size: 1.1em;
}

.envNode {
    padding: 4px 32px 4px 32px;
    background-color: $secondary !important;
    color: $primary !important;
    border-radius: 8px;
    margin: 4px 4px 0px 0px;
    font-size: 1.1em;
}

.envNodeIsNotCollecting {
    padding: 4px 32px 4px 32px;
    background-color: $secondary !important;
    color: $primary !important;
    border-radius: 8px;
    margin: 4px 4px 0px 0px;
    font-size: 1.1em;
}

.envNodeIsCollecting {
    padding: 4px 32px 4px 32px;
    background-color: $lightError !important;
    color: $primary !important;
    border-radius: 8px;
    margin: 4px 4px 0px 0px;
    font-size: 1.1em;
}

.envNode:hover {
    padding: 4px 32px 4px 32px;
    background-color: $secondaryDark !important;
    color: $primary !important;
    border-radius: 8px;
    margin: 4px 4px 0px 0px;
    font-size: 1.1em;
}

.list {
    list-style-type: none;
    border-left: 2px solid $gray !important;
    margin: 0px;
}

.hr {
    border-bottom: 2px solid $gray !important;
    width: 3%;
    margin: 0px;
    padding: 0px;
}

// credits
.creditsCard{
    background-color: #fafffe;
    max-width: 50%;
    align-self: center;
}

.creditsImg{
    height: 150px;
}