@use './../../mainStyle.scss' as ms;

.navbg {
  background-color: ms.$primary !important;
}

.navitemfg {
  color: white !important;
  border: 1px solid white;
  border-radius: 8px;
  margin: 0px 6px;
}

.navitemfg:hover {
  background-color: white;
  color: #004589 !important;
}