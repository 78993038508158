.header {
  margin: 2rem 10%;
  padding: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #000000;
}

.logoHeader{
  height: 124px;
}